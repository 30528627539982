export default [
    {
        title: "Consignment no.",
        key: "consignment_no",
        toggled: true,
        sortable: true,
        width: "w-48"
    },
    {
        title: "Owner (from source)",
        key: "owner_from_source",
        toggled: true,
        sortable: true,
        width: "w-40"
    },
    {
        title: "Site",
        key: "owner_site_from_source",
        toggled: true,
        sortable: true,
        width: "w-24"
    },
    {
        title: "From",
        key: "from",
        toggled: false,
        sortable: true,
        width: "w-40"
    },
    {
        title: "From address",
        key: "consignor_address",
        toggled: false,
        sortable: true,
        width: "w-40"
    },
    {
        title: "From city",
        key: "consignor_city",
        toggled: false,
        sortable: true,
        width: "w-32"
    },
    {
        title: "From post code",
        key: "consignor_postcode",
        toggled: false,
        sortable: true,
        width: "w-48"
    },
    {
        title: "From country",
        key: "consignor_countrycode",
        toggled: false,
        sortable: true,
        width: "w-40"
    },

    {
        title: "To",
        key: "to",
        toggled: false,
        sortable: true,
        width: "w-32"
    },

    {
        title: "To address",
        key: "consignee_address",
        toggled: false,
        sortable: true,
        width: "w-48"
    },
    {
        title: "To city",
        key: "consignee_city",
        toggled: false,
        sortable: true,
        width: "w-32"
    },
    {
        title: "To post code",
        key: "consignee_postcode",
        toggled: false,
        sortable: true,
        width: "w-32"
    },
    {
        title: "To country",
        key: "consignee_countrycode",
        toggled: false,
        sortable: true,
        width: "w-32"
    },


    {
        title: "Pickup name",
        key: "pickup_name",
        toggled: false,
        sortable: true,
        width: "w-32"
    },

    {
        title: "Pickup address",
        key: "pickup_address",
        toggled: false,
        sortable: true,
        width: "w-48"
    },
    {
        title: "Pickup city",
        key: "pickup_city",
        toggled: false,
        sortable: true,
        width: "w-32"
    },
    {
        title: "Pickup post code",
        key: "pickup_postcode",
        toggled: false,
        sortable: true,
        width: "w-48"
    },
    {
        title: "Pickup country",
        key: "pickup_countrycode",
        toggled: false,
        sortable: true,
        width: "w-40"
    },

    {
        title: "Delivery name",
        key: "delivery_name",
        toggled: false,
        sortable: true,
        width: "w-40"
    },
    {
        title: "Delivery address",
        key: "delivery_address",
        toggled: false,
        sortable: true,
        width: "w-40"
    },
    {
        title: "Delivery city",
        key: "delivery_city",
        toggled: false,
        sortable: true,
        width: "w-32"
    },
    {
        title: "Delivery post code",
        key: "delivery_postcode",
        toggled: false,
        sortable: true,
        width: "w-48"
    },
    {
        title: "Delivery country",
        key: "delivery_countrycode",
        toggled: false,
        sortable: true,
        width: "w-32"
    },


    {
        title: "Payer",
        key: "payer",
        toggled: false,
        sortable: true,
        width: "w-32"
    },
    {
        title: "Combiterms",
        key: "combiterms",
        toggled: false,
        sortable: true,
        width: "w-32"
    },
    {
        title: "Date",
        key: "ordered_at",
        toggled: true,
        width: "w-24",
        sortable: true
    },
    {
        title: "Mode",
        key: "transport_mode",
        toggled: true,
        sortable: true,
        width: "w-24"
    },
    {
        title: "Weight",
        key: "weight",
        toggled: false,
        sortable: true,
        width: "w-24",
        unit: "kg"
    },
    {
        title: "Volume",
        key: "dimensions_volume",
        toggled: false,
        sortable: true,
        width: "w-24",
    },
    {
        title: "Loading metres",
        key: "dimensions_loadingmetres",
        toggled: false,
        sortable: true,
        width: "w-40",
    },
    {
        title: "Direction",
        key: "direction",
        toggled: false,
        sortable: true,
        width: "w-32"
    },
    {
        title: "Fuel",
        key: "fuel_amount",
        toggled: false,
        sortable: true,
        width: "w-32"
    },
    {
        title: "CO2 total",
        key: "co2_total_kg",
        toggled: false,
        sortable: true,
        width: "w-32",
        unit: "kg"
    },
    {
        title: "CO2 fossil",
        key: "co2_fossil_kg",
        toggled: false,
        sortable: true,
        width: "w-32",
        unit: "kg"
    },
    {
        title: "CO2 biogen",
        key: "co2_biogen_kg",
        toggled: false,
        sortable: true,
        width: "w-32",
        unit: "kg"
    },
    {
        title: "CO2e",
        key: "co2e_kg",
        toggled: true,
        sortable: true,
        width: "w-32",
        unit: "kg"
    },
    {
        title: "SO2",
        key: "so2_g",
        toggled: false,
        sortable: true,
        width: "w-32",
        unit: "g"
    },
    {
        title: "CO",
        key: "co_g",
        toggled: false,
        sortable: true,
        width: "w-32",
        unit: "g"
    },
    {
        title: "HC",
        key: "hc_g",
        toggled: false,
        sortable: true,
        width: "w-32",
        unit: "g"
    },
    {
        title: "CH4",
        key: "ch4_g",
        toggled: false,
        sortable: true,
        width: "w-32",
        unit: "g"
    },
    {
        title: "NOx",
        key: "nox_g",
        toggled: true,
        sortable: true,
        width: "w-32",
        unit: "g"
    },
    {
        title: "N2O",
        key: "n2o_g",
        toggled: false,
        sortable: true,
        width: "w-32",
        unit: "g"
    },
    {
        title: "CO2e/km/kg",
        key: "co_km_kg",
        toggled: false,
        sortable: true,
        width: "w-32",
        unit: "g"
    },
    {
        title: "PM",
        key: "pm_g",
        toggled: true,
        sortable: true,
        width: "w-32",
        unit: "g"
    },
    {
        title: "Energy",
        key: "energy_mj",
        toggled: true,
        sortable: true,
        width: "w-32",
        unit: "MJ"
    },
    {
        title: "Carrier",
        key: "carrier_from_source",
        toggled: true,
        sortable: true,
        width: "w-24"
    },
    {
        title: "Carrier service",
        key: "carrier_service_from_source",
        toggled: true,
        sortable: true,
        width: "w-40"
    },
    {
        title: "Shippers ref",
        key: "shippers_ref",
        toggled: false,
        sortable: true,
        width: "w-32"
    },
    {
        title: "Recipients ref",
        key: "recipients_ref",
        toggled: false,
        sortable: true,
        width: "w-40"
    },
    {
        title: "Consignee ref",
        key: "consignee_ref",
        toggled: false,
        sortable: true,
        width: "w-40"
    },
    {
        title: "Status",
        key: "status",
        toggled: false,
        sortable: true,
        width: "w-32"
    }
];