<template>
    <div class="relative">
        <svg-importer icon-name="icons/calendar" class="absolute z-10 mt-2 ml-3 pointer-events-none" />
        <input :title="formattedRange" ref="dateRangePicker" type="text" :disabled="disabled" :value="formattedRange" :placeholder="!formattedRange ? 'Select option' : ''" @click="showPicker = true" class="date-range-picker text-ellipsis" />

        <div v-if="clearable && formattedRange" class="absolute top-2 bottom-2 right-0 z-10 flex items-center mr-2 cursor-pointer background-white" @click="clearDate">
            <svg-importer icon-name="icons/close" />
        </div>

        <transition name="slide">
            <div
                class="absolute w__min-300 z-100 w-full mt-1 border border-gray-400 rounded card"
                v-show="showPicker"
                v-closable="{
                    exclude: ['dateRangePicker'],
                    handler: 'onClose'
                }"
            >
                <div class="flex flex-wrap p-4 preset-ranges">
                     <modal-field label="Date range" class="relative w-full px-2">
                        <multiselect
                            v-model="selectedPreDate"
                            :options="presetDates"
                            label="title"
                            :branding="branding"
                            @input="setPresetDates"
                            class="relative"
                        >
                        </multiselect>
                    </modal-field>
                </div>

                <date-range
                    v-if="!range || (createEndDate && createStartDate) || typeof range === 'string'"
                    month-year-format="MMMM YYYY"
                    class="calendar"
                    :sync-range.sync="helperRange"
                    lang="en"
                    :class="[{'no-branding': ! branding}, 'no-selects']"
                    :days-disabled-start="disabledStart"
                    @change="onChange"
                ></date-range>

                <date-range
                    v-else
                    month-year-format="MMMM YYYY"
                    class="calendar"
                    :sync-range.sync="range"
                    lang="en"
                    :class="{'no-branding': ! branding}"
                    :days-disabled-start="disabledStart"
                    @change="onChange"
                ></date-range>
            </div>
        </transition>
    </div>
</template>

<script>
    import moment from "moment";
    import { DateRange } from "vue-date-range";

    const presetDateDictionary = {
        today: {
            title: 'Today',
            days: 'today',
        },
        yesterday: {
            title: 'Yesterday',
            days: 'yesterday',
        },
        'last_3_days': {
            title: '3 days',
            days: 'last_3_days',
        },
        'last_week': {
            title: 'Last week',
            days: 'last_week',
        },
        'last_month': {
            title: 'Last month',
            days: 'last_month',
        },
        'last_3_months': {
            title: 'Last 3 months',
            days: 'last_3_months',
        },
        'last_6_months': {
            title: 'Last 6 months',
            days: 'last_6_months',
        },
        'last_12_months': {
            title: 'Last 12 months',
            days: 'last_12_months',
        },
    };

    export default {
        name: "DateRangePicker",

        components: {
            DateRange
        },

        props: {
            branding: {
                required: false,
                default: true,
                type: Boolean
            },
            createStartDate: {
                required: false,
                default: false,
                type: [Object, Boolean, String]
            },
            createEndDate: {
                required: false,
                default: false,
                type: [Object, Boolean, String]
            },
            fullList: {
                type: Boolean,
                default: false,
            },
            loadedRange: {},

            removeDefaultValue: {
                type: Boolean,
                default: false,
            },
            disabled: {
                required: false,
                default: false,
                type: Boolean
            },
            fullValue: {
                required: false,
                default: null,
            },
            trigger: {
                type: Number,
                default: 0
            },
            removeAllItem: {
                type: Boolean,
                default: false,
            },
            createdComponents: {
                type: Boolean,
                default: false,
            },
            initAll: {
                type: Boolean,
                default: false,
            },
            clearable: {
                type: Boolean,
                default: false,
            },
            disabledStart: null,
        },

        data: () => ({
            showPicker: false,
            range: {
                startDate: moment(),
                endDate: moment()
            },
            helperRange: {
                startDate: moment(),
                endDate: moment()
            },
            selectedPreDate: null,
            presetDates: [
                // {
                //     title: 'All time',
                //     days: 'all',
                //     active: false,
                // },
                {
                    ...presetDateDictionary.today,
                    active: false,
                },
                {
                    ...presetDateDictionary.yesterday,
                    active: false,
                },
                {
                    ...presetDateDictionary.last_3_days,
                    active: false,
                },
                {
                    ...presetDateDictionary.last_week,
                    active: false,
                },
                {
                    ...presetDateDictionary.last_month,
                    active: false,
                },
                {
                    ...presetDateDictionary.last_3_months,
                    active: false,
                },
                {
                    ...presetDateDictionary.last_6_months,
                    active: false,
                },
                {
                    ...presetDateDictionary.last_12_months,
                    active: false,
                },
            ],
        }),

        computed: {
            formattedRange() {
                if (typeof this.range === 'string') {
                    return this.presetDates?.find(item => { return item.days === this.range })?.title;
                }

                if (typeof this.fullValue === 'string') {
                    return this.presetDates?.find(item => { return item.days === this.fullValue })?.title;
                }

                if (this.removeDefaultValue && this.createStartDate === null && this.createEndDate === null) {
                    return ''
                }

                if (this.removeDefaultValue && typeof this.createStartDate === 'boolean' && typeof this.createEndDate === 'boolean') {
                    return '';
                }

                if (!this.fullValue) {
                    return (
                        this.range.startDate.format("LL") +
                        " - " +
                        this.range.endDate.format("LL")
                    )
                }

                return (
                    this.fullValue.startDate.format("LL") +
                    " - " +
                    this.fullValue.endDate.format("LL")
                );
            },
        },

        beforeMount() {
            if (this.createdComponents) {
                return;
            }

            if (this.removeDefaultValue) {
                this.range = {
                    startDate: '',
                    endDate: ''
                }

                return;
            }

            if (this.initAll && (this.fullValue && !this.fullValue.startDate)) {
                this.range = 'all';

                this.$emit('change', this.range);

                return;
            }

            const defaultRange = {
                startDate: moment().subtract(30, "days"),
                endDate: moment(),
            }

            this.range = this.loadedRange ? this.loadedRange : defaultRange;

            this.$emit('change', this.range)
        },

        methods: {
            clearDate() {
                this.range = {
                    startDate: '',
                    endDate: ''
                }

                this.selectedPreDate = null;

                this.$emit('change', this.range)
            },
            disabledRange: function (dayMoment) {

                return Math.abs(dayMoment.diff(moment(),'month')) > 2;
            },
            onChange(range) {
                this.helperRange = null;
                this.range = range;
                this.selectedPreDate = null;

                setTimeout(() => {
                    this.onClose();
                    this.$emit('change', range);
                }, 200);
            },
            setRange(p) {
                if (typeof p === "number") {
                    this.range = {
                        startDate: moment().add(p, "days"),
                        endDate: moment()
                    };
                }
            },
            onClose () {
                this.showPicker = false;
            },
            setPresetDates(value) {
                this.range = value.days;
                this.helperRange = this.getPresetDateRange(value.days);

                this.$emit('change', this.range);

                this.onClose();
            },
            getPresetDateRange(value) {
                if (!(value in presetDateDictionary)) {
                    return;
                }

                if (value === presetDateDictionary.today.days) {
                    return { startDate: moment(), endDate: moment() };
                }

                if (value === presetDateDictionary.yesterday.days) {
                    return { startDate: moment().subtract(1, 'days'), endDate: moment().subtract(1, 'days') };
                }

                if (value === presetDateDictionary.last_3_days.days) {
                    return { startDate: moment().subtract(3, 'days'), endDate: moment() };
                }

                if (value === presetDateDictionary.last_week.days) {
                    return { startDate: moment().subtract(1, 'weeks'), endDate: moment() };
                }

                if (value === presetDateDictionary.last_month.days) {
                    return { startDate: moment().subtract(1, 'months'), endDate: moment() };
                }

                const months = presetDateDictionary[value].days.match(/\d+/)[0];

                return { startDate: moment().subtract(months, 'months'), endDate: moment() };
            },
            setSelectedPreDate(value) {
                if (!value) {
                    return;
                }

                if (typeof value === 'string') {
                    this.selectedPreDate = this.presetDates.find(item => { 
                        return item.days === value 
                    });

                    this.helperRange = this.getPresetDateRange(value);

                    return;
                }

                if (typeof value.startDate === 'object') {
                    const result = moment(value.endDate).diff(moment(value.startDate), 'days');

                    const findIndex = this.presetDates.findIndex(item => {
                        return item.days === result;
                    });

                    this.selectedPreDate = this.presetDates[findIndex];
                }
            }
        },
        watch: {
            trigger() {
                if (typeof this.fullValue === 'string') {
                    this.range = this.fullValue;

                    this.setSelectedPreDate(this.fullValue);

                    this.onClose()

                    this.$emit('change', this.fullValue);

                    return;
                }


                this.range = {
                    startDate: this.fullValue.startDate,
                    endDate: this.fullValue.endDate,
                }

                this.selectedPreDate = null;
            },
            range(value) {
                this.setSelectedPreDate(value);
            },
            fullValue(value) {
                this.setSelectedPreDate(value);
            },
        },
        created() {
            if (!this.fullList) {
                this.presetDates.splice(0,1);
            }

            this.setSelectedPreDate(this.fullValue);
        },
    };
</script>

<style lang="scss">
    .date-range-picker {
        @apply pl-10 #{!important}
    }
    .v-date-calendar .v-date-month-year {
        @apply border-b text-lg py-4 px-2 mb-4;

        flex: 0;
    }

    .calendar.no-branding .v-date-in-range {
        background-color: var(--brand-color) !important;
    }

    .calendar.no-branding .v-date-selected.v-date-start-day {
        background-color: var(--brand-color) !important;
    }

    .calendar.no-branding .v-date-end-day {
        background-color: var(--brand-color) !important;
    }

    .no-branding .v-date-month-year .v-date-arrow.v-date-next {
        border-right: 1px solid var(--brand-color) !important;
        border-top: 1px solid var(--brand-color) !important;
    }

    .no-branding .v-date-month-year .v-date-arrow.v-date-prev {
        border-left: 1px solid var(--brand-color) !important;
        border-top: 1px solid var(--brand-color) !important;
    }

    .no-selects {
        .v-date-selected {
            background-color: transparent;
            color: #4A4A4A;
        }
    }
</style>
