<template>
    <div>
        <card title="Filter" bodyClass="overflow-visible" class="border-radius" title-no-border>
            <div slot="tools" class="flex">
                <template>
                    <icon name="icons/save" title="Save filters" type="button" class="mr-5" @click="showSaveQueryModal = true">
                        <span class="ml-1">Save filter</span>
                    </icon>

                    <icon name="icons/load-query" title="Load filter" type="button" class="mr-5" @click="showLoadQueryModal = true">
                        <span class="ml-1">Load filter</span>
                    </icon>
                </template>
                <confirm class="ml-4" confirm-text="Reset form" @confirmed="resetData">
                    <icon name="icons/reset" title="Reset">
                        <span class="ml-1">Reset</span>
                    </icon>
                </confirm>
            </div>
            <slot />
        </card>

        <modal :show="showSaveQueryModal" @hide="showSaveQueryModal = false" :loader="savingQuery" size="sm">
            <h2 slot="header">Save query</h2>
            <div slot="body">
                <modal-field label="Query name:">
                    <input type="text" v-model="saveQuery.title" />
                </modal-field>
            </div>

            <div slot="footer" class="flex w-full">
                <button
                    class="ml-auto mr-4 text-sm text-red-500"
                    @click="showSaveQueryModal = false"
                >Cancel</button>
                <button class="btn" :disabled="!saveQuery.title" @click="postSaveQuery">Save</button>
            </div>
        </modal>

        <modal :show="showLoadQueryModal" @hide="showLoadQueryModal = false" size="2xl">
            <h2 slot="header">Load query</h2>
            <div slot="body" class="pb-24">
                <div class="flex items-center w-full pt-2 mb-2" :class="{'border-t': index > 0}" v-for="(query, index) in loadedQueries" :key="index">
                    <span class="capitalize">{{ query.title }}</span>
                    <span class="ml-3 text-sm text-gray-600">
                        Created {{ query.created_at }}
                        |
                        From {{ query.filterable_type_translated }}
                    </span>

                    <confirm class="ml-auto" confirm-text="Yes" @confirmed="loadQuery(query)">
                        <button class="ml-auto btn btn-sm">Load</button>
                    </confirm>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'FilterTools',

        props: {
            name: {
                required: true,
                type: String
            },
            filters: {
                required: true,
                type: Object
            },
            filterCategory: {
                required: true,
                type: String
            },
        },

        data: () => ({
            showSaveQueryModal: false,
            savingQuery: false,
            saveQuery: {
                title: ""
            },

            showLoadQueryModal: false,

            loadedQueries: [],
            queries: [],
        }),

        computed: {
            user() {
                return this.$store.getters['auth/user']
            },
        },

        created () {
            this.customFilterLayout = this.filterLayout;
            this.fetchFilterQueries();
        },

        methods: {
            resetData() {
                this.$emit('reset')
            },

            loadQuery(query){
                let title = query.title
                let queryData = query.data
                
                this.$emit('loaded', queryData.filters)

                this.showLoadQueryModal = false;
                this.$snotify.success(`Query "${title}" loaded!`)
            },

            async postSaveQuery() {
                this.savingQuery = true

                const queryData = {
                    filters: this.filters,
                };

                const { data } = await axios.post(this.$apiUrl.filter.base, {
                    data: queryData,
                    title: this.saveQuery.title,
                    category: this.filterCategory
                }).catch(() => {
                    this.savingQuery = false;
                    this.showSaveQueryModal = false;
                });

                this.query = data.data

                this.savingQuery = false;
                this.showSaveQueryModal = false;

                this.$snotify.success(`Query "${this.saveQuery.title}" saved!`)

                this.saveQuery.title = ""

                this.fetchFilterQueries();
            },

            async fetchFilterQueries() {
                if (!this.filterCategory) {
                    return;
                } 

                const { data } = await axios.get(`${this.$apiUrl.filter.base}?filter[category]=${this.filterCategory}`);
                this.loadedQueries = data.data;
            },
        }
    }
</script>
